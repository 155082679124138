// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import Grid from "@material-ui/core/Grid";
import {Button, FormControlLabel, Checkbox} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { v4 as uuidv4 } from 'uuid';
import { assign, filter, find, get, keyBy, map } from 'lodash-es';
import Bluebird from 'bluebird';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../components/100Include/ErrorMessage";
import moment from "moment";
import {apiTenant} from "../../Api/_ApiTenant";
import { apiAuction } from "../../Api/_ApiAuction";
import {addMessage} from "../../Redux/Action/messageAction";
import {apiUsers} from "../../Api/_ApiUsers";
import TenantMemberDetails from './TenantMemberDetails';

class TenantMemberUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            tenantMember: {
                tenant_member_id: '',
                tenant: '',
                display_name: '',
                credit: '',
                max_credit: '',
                qr_code: '',
                activated: false,
                active: false,
                tenant_member_role: '',
                tenant_member_platforms: [],
                power_bid_platforms: [],
            },
            platforms: [],
            tenantMemberRoles: [],
            roleValidateMsg: null,
            clientId: null,
            clientIdRetrieved: false,
            platformsRetrieved: false,
            powerBidPlatformInited: false,
        };
    }

    componentDidMount() {
        this.getPlatforms();
        if(this.props.id) {
            this.setState({isEdit: true})
            this.getTenantMemberDetail();
        } else {
            const { t } = this.props;
            const userItemBreadcrumb = [{
                title: t("TenantMemberManagement:addTenantMember"),
                link: null
            }];
            this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
            this.setState({
                clientIdRetrieved: true,
                platformsRetrieved: true,
            }, () => {
                this.initPowerBidPlatforms();
            });
        }
    }

    getTenantMemberDetail = () => {
        const params = {
            '$expand': 'tenant_member_platforms,client/',
        };
        apiTenant.getTenantMemberDetail(this.props.id, params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    const tenantMember = obj.data;
                    tenantMember.power_bid_platforms = [];
                    const userItemBreadcrumb = [{
                        title: `Edit Member [${tenantMember.display_name}]`,
                        link: null
                    }];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                    this.setState({
                        tenantMember: tenantMember,
                        clientId: get(tenantMember, ['client', 'client_id']),
                        clientIdRetrieved: true,
                    }, () => {
                        this.initPowerBidPlatforms();
                    });
                }
            }
        })
    }

    getPlatforms = () => {
        return Promise.all([
            apiTenant.getTenantMemberRoles(),
            apiAuction.getTenantSuppliers(localStorage.getItem('asTenantId'))
        ]).then(([roleResult, supplierResult]) => {
            let {tenantMemberRoles, platforms} = this.state;
            if (roleResult && roleResult.status === 200) {
                if (roleResult.data) {
                    tenantMemberRoles = roleResult.data;
                }
            } else {
                console.log(roleResult.error);
            }
            if (supplierResult && supplierResult.status === 200) {
                if (supplierResult.data) {
                    platforms = supplierResult.data;
                }
            } else {
                console.log(supplierResult.error);
            }
            const updatedStates = { tenantMemberRoles, platforms, platformsRetrieved: true };
            this.setState(updatedStates, () => {
                this.initPowerBidPlatforms();
            });
            return updatedStates;
        });
    }

    initPowerBidPlatforms = () => {
        const { clientIdRetrieved, platformsRetrieved } = this.state;
        if (clientIdRetrieved && platformsRetrieved) {
            const { tenantMember, platforms, clientId } = this.state;
            const power_bid_platforms = clientId ? map(filter(platforms, ['power_bid_client', clientId]), 'supplier_id') : [];
            const newTenantMember = assign({}, tenantMember, {
                power_bid_platforms
            });
            this.setState({
                tenantMember: newTenantMember,
                powerBidPlatformInited: true,
            });
        }
    }

    // BUTTON FUNCTION
    cancel = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/client-management?previous=true');
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    _addTenantMemberAsync = (value) => {
        const { i18n } = this.props;
        const { platforms, clientId } = this.state;
        const addTenantMemberBody = {
            tenant: localStorage.getItem('asTenantId'),
            activated: false,
            display_name: value.display_name,
            credit: +value.credit,
            max_credit: +value.max_credit,
            active: value.active,
            tenant_member_role: value.tenant_member_role,
            tenant_member_platforms: value.tenant_member_platforms,
            qr_code: uuidv4(),
        };
        return apiTenant.addTenantMember(addTenantMemberBody).then(async (obj) => {
            await this.updatePowerBidUser(platforms, value.power_bid_platforms, clientId);
            if (obj.status === 201) {
                const msgDsp = {
                    messageSnackbar: 'Add member successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/client-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Add member failed';
                throw new Error(error);
            }
        }).catch(error => {
            const msgDsp = {
                messageSnackbar: error.message,
                variantSnackbar: 'error',
                key: new Date().getTime(),
            };
            this.props.addMessageP(msgDsp);
        });

    }

    _updateTenantMemberAsync = (value) => {
        const { i18n } = this.props;
        const { tenantMember, platforms, clientId } = this.state;
        const { credit_transaction, ...tenantMemberBody } = tenantMember;
        let updateTenantMemberBody = {
            ...tenantMemberBody,
            display_name: value.display_name,
            credit: +value.credit,
            max_credit: +value.max_credit,
            active: value.active,
            tenant_member_role: value.tenant_member_role,
            tenant_member_platforms: value.tenant_member_platforms,
        };
        return apiTenant.fullUpdateTenantMember(tenantMember.tenant_member_id, updateTenantMemberBody).then(async (obj) => {
            await this.updatePowerBidUser(platforms, value.power_bid_platforms, clientId);
            if (obj.status === 200) {
                const msgDsp = {
                    messageSnackbar: 'Update member successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/client-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update member failed';
                throw new Error(error);
            }
        }).catch(error => {
            const msgDsp = {
                messageSnackbar: error.message,
                variantSnackbar: 'error',
                key: new Date().getTime(),
            };
            this.props.addMessageP(msgDsp);
        });
    }

    updatePowerBidUser(platforms, selectedPlatformIds, clientId) {
        if (!clientId) {
            if (selectedPlatformIds.length > 0) {
                throw new Error('Power bid can only be saved after an user joined');
            } else {
                return Promise.resolve([]);
            }
        }
        const selectedSupplierIdMap = keyBy(selectedPlatformIds);
        return Bluebird.map(platforms, platform => {
            const supplierId = platform.supplier_id;
            const isNewPowerBidClient = selectedSupplierIdMap.hasOwnProperty(supplierId);
            const isExistingPowerBidClient = platform.power_bid_client === clientId;
            if (isNewPowerBidClient != isExistingPowerBidClient) {
                return apiAuction.updateSupplier(supplierId, {
                    power_bid_client: isNewPowerBidClient ? clientId : null
                });
            }
        }, {
            concurrency: 4
        });
    }

    _submitTenantMemberForm = (value) => {
        if(this.state.isEdit) {
            this._updateTenantMemberAsync(value);
        } else {
            this._addTenantMemberAsync(value);
        }
    }

    // FORM CONFIG
    formConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t, id } = this.props;
        const { tenantMember, platforms, tenantMemberRoles } = this.state;

        return values && (
            <Form id="userForm" className="full-width" autoComplete="nope">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            {t("TenantMemberManagement:displayName")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="display_name" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.display_name && touched.display_name ? <ErrorMessage message={errors.display_name} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            <span>{t("TenantMemberManagement:tenantRole")}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl className='full-width'>
                                <Field
                                    as={Select}
                                    disableUnderline
                                    name="tenant_member_role"
                                    classes={{ root: 'custom-select' }}
                                >
                                    {map(tenantMemberRoles, tenantMemberRole => (
                                        <MenuItem key={tenantMemberRole.tenant_member_role_id} value={tenantMemberRole.tenant_member_role_id}>
                                            {tenantMemberRole.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20" >
                        <Grid item xs={2} className="form-item">
                            <span>{t("TenantMemberManagement:creditBalance")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="credit" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.credit && touched.credit ? <ErrorMessage message={errors.credit} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <span>{t("TenantMemberManagement:maxCredit")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="max_credit" type="text" placeholder="" maxLength="100"/>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.max_credit && touched.max_credit ? <ErrorMessage message={errors.max_credit} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            <span>{t("TenantMemberManagement:platforms")}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl className='full-width'>
                                <Field name="tenant_member_platforms">
                                    {({ field, form, meta }) => (
                                        <Select
                                            multiple
                                            disableUnderline
                                            name="tenant_member_platforms"
                                            value={map(field.value, 'platform')}
                                            onChange={e => {
                                                const selectedPlatformIds = e.target.value;
                                                const formattedValue = map(selectedPlatformIds, platformId => {
                                                    const matchPlatformId = ['platform', platformId];
                                                    const existingTenantMemberPlatform = find(tenantMember.tenant_member_platforms, matchPlatformId);
                                                    const tenantMemberPlatformFieldValue = find(field.value, matchPlatformId);
                                                    return existingTenantMemberPlatform ? {
                                                        ...existingTenantMemberPlatform,
                                                        extended_bid: tenantMemberPlatformFieldValue ? tenantMemberPlatformFieldValue.extended_bid : false,
                                                    } : { platform: platformId, extended_bid: false };
                                                });
                                                e.target.value = formattedValue;
                                                handleChange('power_bid_platforms')({
                                                    ...e,
                                                    target: {
                                                        name: 'power_bid_platforms',
                                                        value: filter(values.power_bid_platforms, platformId => selectedPlatformIds.includes(platformId)),
                                                    }
                                                });
                                                return field.onChange(e);
                                            }}
                                            classes={{ root: 'custom-select select-multiple-checkbox' }}
                                            input={<Input className="select-multiple-checkbox-input" />}
                                            renderValue={selectedValues => map(selectedValues, platformId => {
                                                const selectedPlatform = find(platforms, ['supplier_id', platformId]);
                                                return get(selectedPlatform, 'name', '');
                                            }).join(', ')}
                                        >
                                            {map(platforms, platform => {
                                                const platformId = platform.supplier_id;
                                                return <MenuItem key={platformId} value={platformId}>
                                                    <Checkbox checked={!!find(field.value, ['platform', platformId])} />
                                                    <ListItemText primary={platform.name} />
                                                </MenuItem>
                                            })}
                                        </Select>
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            <span>{t("TenantMemberManagement:powerBid")}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl className='full-width'>
                                <Field name="power_bid_platforms">
                                    {({ field, form, meta }) => {
                                        return (
                                        <Select
                                            multiple
                                            disableUnderline
                                            name="power_bid_platforms"
                                            value={field.value || []}
                                            onChange={field.onChange}
                                            classes={{ root: 'custom-select select-multiple-checkbox' }}
                                            input={<Input className="select-multiple-checkbox-input" />}
                                            renderValue={selectedValues => map(selectedValues, platformId => {
                                                const selectedPlatform = find(platforms, ['supplier_id', platformId]);
                                                return get(selectedPlatform, 'name', '');
                                            }).join(', ')}
                                        >
                                            {map(values.tenant_member_platforms, tenantMemberPlatform => {
                                                const platformId = tenantMemberPlatform.platform;
                                                const platform = find(platforms, ['supplier_id', platformId]);
                                                return platform ? (
                                                    <MenuItem key={platformId} value={platformId}>
                                                        <Checkbox checked={!!find(field.value, (v) => v === platformId)} />
                                                        <ListItemText primary={platform.name} />
                                                    </MenuItem>
                                                ) : null;
                                            })}
                                        </Select>
                                    )}}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            <span>{t("TenantMemberManagement:extendedBid")}</span>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl className='full-width'>
                                <Field name="tenant_member_platforms">
                                    {({ field, form, meta }) => (
                                        <Select
                                            multiple
                                            disableUnderline
                                            name="tenant_member_platforms"
                                            value={map(filter(field.value, 'extended_bid'), 'platform')}
                                            onChange={e => {
                                                const selectedPlatformIds = e.target.value;
                                                const formattedValue = map(field.value, tenantMemberPlatform => ({
                                                    ...tenantMemberPlatform,
                                                    extended_bid: selectedPlatformIds.includes(tenantMemberPlatform.platform),
                                                }));
                                                e.target.value = formattedValue;
                                                handleChange('power_bid_platforms')({
                                                    ...e,
                                                    target: {
                                                        name: 'power_bid_platforms',
                                                        value: filter(values.power_bid_platforms, platformId => selectedPlatformIds.includes(platformId)),
                                                    }
                                                });
                                                return field.onChange(e);
                                            }}
                                            classes={{ root: 'custom-select select-multiple-checkbox' }}
                                            input={<Input className="select-multiple-checkbox-input" />}
                                            renderValue={selectedValues => map(selectedValues, platformId => {
                                                const selectedPlatform = find(platforms, ['supplier_id', platformId]);
                                                return selectedPlatform ? selectedPlatform.name : '';
                                            }).join(', ')}
                                        >
                                            {field.value.map(tenantMemberPlatform => {
                                                const platformId = tenantMemberPlatform.platform;
                                                const platform = find(platforms, ['supplier_id', platformId]);
                                                return platform ? (
                                                    <MenuItem key={platformId} value={platformId}>
                                                        <Checkbox checked={tenantMemberPlatform.extended_bid} />
                                                        <ListItemText primary={platform.name} />
                                                    </MenuItem>
                                                ) : null;
                                            })}
                                        </Select>
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20" >
                        <Grid item xs={2} className="form-item">
                            <span>{t("TenantMemberManagement:active")}</span>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <FormControlLabel
                                name="active"
                                checked={values.active}
                                onChange={handleChange}
                                control={<Checkbox />}
                                label=""
                            />
                        </Grid>
                    </Grid>

                    {values.isEdit &&
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt10">
                        <Grid item xs={2} className="form-item">
                            {t("TenantMemberManagement:updatedDate")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            {values.lastmoddate ? moment(values.lastmoddate).format('YYYY-MM-DD HH:mm:ss') : ''}

                        </Grid>
                    </Grid>
                    }
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">{id ? 'Save' : 'Add'}</Button>
                        <Button type="button" className="second-button" onClick={() => { this.cancel() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, tenantMember, powerBidPlatformInited } = this.state;
        const inputTenantMember = powerBidPlatformInited ? {...tenantMember, isEdit: isEdit} : null;
        const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

        const Schema = Yup.object().shape({
            display_name: Yup.string().required("Username is required"),
            credit: Yup.string().required("Credit Balance is required").matches(/^[0-9]+\.*[0-9]*$/, "Credit Balance number is not valid"),
            max_credit: Yup.string().required("Max credit is required").matches(/^[0-9]+\.*[0-9]*$/, "Max credit is not valid"),
        });

        return (
            tenantMember && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputTenantMember}
                            validationSchema={Schema}
                            onSubmit={this._submitTenantMemberForm}
                            component={this.formConfiguration}
                        />
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantMemberUpdate)));
