// Essential for all components
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as sha1 from 'js-sha1';
// Redux
import { connect } from 'react-redux';

// Material UI
import {withStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import {Button} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Include
import {getOrderData, getSelectedData} from "../../utils/02MaterialDesign/EnhancedTableUtil";
import EnhancedTable from "../../components/102MaterialDesign/EnhancedTable/EnhancedTable";
import Files from "react-files";
import {setPaginator} from "../../Redux/Action/paginatorAction";

const styles = {
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    }
};

class BaseListPage extends Component {

    constructor(props) {
        super(props);
        this.myFiles = React.createRef();
        this.state = {
            // table settings
            data: [],
            order: this.props.orderDefault,
            orderBy: this.props.orderByDefault,
            selected: [],
            page: props.currentPage || 0,
            rowsPerPage: this.props.rowsPerPageDefault,

            // component state
            search_type: '',
            search_keyword: '',
            // file
            fileUpload: null,
            alertDialogOpen:false,
        };

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.data !== prevState.data) {
            return {
                data: nextProps.data, selected: []
            };
        } else {
            return {...nextProps}
        }
    }

    _searchData = () => {
        const {order, orderBy, rowsPerPage, search_keyword} = this.state;
        this.props.getDataDynamic(0, rowsPerPage, orderBy, order, true, search_keyword);
    }

    _getDataDynamic = (page, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        if (typeof this.props.getDataDynamic === 'function') {
            let skipAmount = (page)*rowsPerPage;

            if(page !== 0 && this.isRequestPageEmpty(skipAmount)){
                this.setState({selected: []});
                this.setState({ page: 0 });
                this.settingPreviousPaginator(this.props.pageId, rowsPerPage, 0, orderBy, order);
                skipAmount = 0;
            }

            console.log(`Page: ${page}, PerPage: ${rowsPerPage}, Skip: ${skipAmount}, OrderBy: ${orderBy}, Order: ${order}`);
            this.props.getDataDynamic(skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject);
        }
    }

    _createButtonAction = () => {
        if (typeof this.props.createButtonAction === 'function') {
            this.props.createButtonAction();
        }
    }

    _updateButtonAction = () => {
        if (typeof this.props.updateButtonAction === 'function') {
            this.props.updateButtonAction(this.state.selected);
        }
    }

    _deleteButtonAction = () => {
        if (typeof this.props.deleteButtonAction === 'function') {
            this.props.deleteButtonAction(this.state.selected, this.state.rowsPerPage);
        }
    }

    _importButtonAction = () => {
        if (typeof this.props.importButtonAction === 'function') {
            if(this.state.fileUpload) {
                const file = {...this.state.fileUpload}
                this.setState({
                    fileUpload: null
                }, () => this.props.importButtonAction(file,this.state.rowsPerPage));
            }
        }
    }

    _exportButtonAction = () => {
        if (typeof this.props.exportButtonAction === 'function') {
            this.props.exportButtonAction();
        }
    }

    _sendPushButtonAction = () => {
        if (typeof this.props.sendPushButtonAction === 'function') {
            this.props.sendPushButtonAction(this.state.selected);
        }
    }

    _sendWeChatButtonAction = () => {
        if (typeof this.props.sendWeChatButtonAction === 'function') {
            this.props.sendWeChatButtonAction(this.state.selected);
        }
    }

    _sendOneToOneButtonAction = () => {
        if (typeof this.props.sendOneToOneButtonAction === 'function') {
            this.props.sendOneToOneButtonAction(this.state.selected);
        }
    }

    _okButtonAction = () => {
        if (typeof this.props.okButtonAction === 'function') {
            this.props.okButtonAction(this.state.selected);
        }
    }

    _cancelButtonAction = () => {
        if (typeof this.props.cancelButtonAction === 'function') {
            this.props.cancelButtonAction();
        }
    }

    clickToFunction = (id) => {
        if (typeof this.props.clickToFunction === 'function') {
            this.props.clickToFunction(id);
        }
    }

    isRequestPageEmpty(skipAmount){
        return (skipAmount>=this.props.amountOfData)
    }

    // FILE CONTROL
    onFilesChange = (files) => {
        if(files && files.length > 0 ) {
            const file = files[files.length - 1];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                var fr = new FileReader();
                fr.onloadend = () => {
                    const fileName = (file.name).replace(/\s|-/g, '_');
                    console.log("file sha1",sha1(fr.result));
                    const dataForLib = {
                        file_url: `Content-Type:${file.type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}\r\nContent-Disposition:attachment;filename="${encodeURI(fileName)}"\r\nContent-Transfer-Encoding: base64\r\n\r\n${reader.result.split('base64,')[1]}`,
                        file_name: fileName,
                        file_size: parseInt(file.size),
                        sha1_hash: sha1(fr.result)
                    }
                    this.setState({
                        fileUpload: dataForLib
                    }, () => this._importButtonAction());
                };
                fr.readAsArrayBuffer(file);
            };
        } else {
            this.setState({
                fileUpload: null
            });
        }
    }

    onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }


    handleRequestSort = (event, property) => {
        const {page, rowsPerPage, search_keyword} = this.state;
        const orderData = getOrderData(property, this.state.orderBy, this.state.order);
        this.setState({ order: orderData.order, orderBy: orderData.orderBy }, () => {
            this.settingPreviousPaginator(this.props.pageId, rowsPerPage, page, orderData.orderBy, orderData.order);
            this._getDataDynamic(page, rowsPerPage, orderData.orderBy, orderData.order, true, search_keyword);
        });
    };

    handleSelectAllClick = event => {

        if (event.target.checked) {
            this.setState({ selected: this.props.data.map(n => n.id)});
            return;
        }
        this.setState({ selected: []});
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const newSelected = getSelectedData(id, selected);
        this.setState({ selected: newSelected });
    };

    settingPreviousPaginator = (page_id, rowsPerPage, page, orderBy, order) => {
        if(this.props.pageId) {
            this.props.setPaginator({page_id: page_id, rowsPerPage: rowsPerPage, page: page, orderBy: orderBy, order: order })
        }
    }

    handleChangePage = (event, page) => {
        if(event) {
            const {rowsPerPage, order, orderBy, search_keyword} = this.state;
            this.setState({selected: []});
            this.setState({ page });
            this.settingPreviousPaginator(this.props.pageId, rowsPerPage, page, orderBy, order);
            this._getDataDynamic(page,rowsPerPage,orderBy,order, false, search_keyword);
        }
    };

    handleChangeRowsPerPage = event => {
        const {page, order, orderBy, search_keyword} = this.state;
        this.setState({ rowsPerPage: parseInt(event.target.value) });
        this.settingPreviousPaginator(this.props.pageId, parseInt(event.target.value), page, orderBy, order);
        this._getDataDynamic(page, event.target.value, orderBy, order, false, search_keyword );
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleSearchChange = event => {
        this.setState({ search_keyword: event.target.value });
    };

    handleKeyEvent = event => {
        if (event.key === 'Enter') {
            this._searchData();
        }
    }

    handleResetSearch = () => {
        this.setState({ search_keyword : ''}, () => this._searchData());

    }

    handleDeleteButtonClick = (displayAlertDialog) => {
        if(displayAlertDialog){
            this.setState({alertDialogOpen:true});
        }
        else{
            this._deleteButtonAction();
        }
    }

    render() {
        const {
            mainClass, classes, primaryKey,
            displaySearchBar,
            displayAlertDialog,
            AlertDialogText, headerContent,
            displayActionBar, createButton, createButtonText, createButtonDisable,
            updateButton, updateButtonText,
            deleteButton, deleteButtonText,
            importButton, importButtonText, importFileTypes,
            exportButton, exportButtonText,
            remainButtons,
            sendPushButton, sendPushButtonText,
            sendWeChatButton, sendWeChatButtonText,
            sendOneToOneButton, sendOneToOneButtonText,
            displaySelectBox,
            rows, data , rowsPerPageOptions,
            amountOfData,
            displayBottomFunction,
            okButton, okButtonText,
            cancelButton, cancelButtonText,
        } = this.props;
        const { order, orderBy, selected, rowsPerPage, page,alertDialogOpen} = this.state;
        const hasLeftActions = !!createButton || !!updateButton || !!deleteButton || !!importButton || !!exportButton || (!!remainButtons && remainButtons.length > 0)
        const hasRightActions = !!sendWeChatButton || !!sendPushButton || !!sendOneToOneButton;
        return (
            <div className={mainClass}>
                <div className="main__container">

                    {displayAlertDialog &&
                    <Dialog
                        open={alertDialogOpen}
                        keepMounted>
                        <DialogTitle>{"Comfirm delete?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {AlertDialogText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button type="button" className="primary-button" onClick={()=>{this._deleteButtonAction();this.setState({alertDialogOpen:false});}} >
                                Confirm
                            </Button>
                            <Button type="button" className="primary-button" onClick={() => this.setState({alertDialogOpen:false})}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    }
                    {
                        headerContent &&
                        <h4>{headerContent}</h4>
                    }
                    {displaySearchBar &&
                    <div className="search-bar pt10">
                        <Grid container alignItems="center">
                            <Grid item xs={10}>
                                <Paper className={classes.root} elevation={1}>
                                    <IconButton className={classes.iconButton} aria-label="Search">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase className={classes.input} placeholder="Search " onChange={this.handleSearchChange} onKeyPress={this.handleKeyEvent} value={this.state.search_keyword}/>

                                    {(this.state.search_keyword !== '') &&
                                    <IconButton className={classes.iconButton} onClick={this.handleResetSearch}>
                                        <ClearIcon />
                                    </IconButton>
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={2} className="form-item button-area">
                                <Button type="button" className="primary-button" onClick={() => this._searchData()}>Search</Button>
                            </Grid>
                        </Grid>
                    </div>
                    }
                    {displayActionBar &&
                    <div className={displaySearchBar ? ' button-wrapper action-bar pt20' : 'button-wrapper action-bar'}>
                        <Grid container xm={12} alignItems="center">
                            <Grid item xs={12} md={hasLeftActions && hasRightActions ? 6 : 12} className="button-wrapper form-item">
                                {createButton && <Button type="button" className="primary-button" disabled={createButtonDisable} onClick={() => this._createButtonAction()}>{createButtonText}</Button>}
                                {updateButton && <Button type="button" className="primary-button" disabled={selected.length !== 1} onClick={() => this._updateButtonAction()}>{updateButtonText}</Button>}
                                {deleteButton && <Button type="button" className="delete-button" disabled={selected.length < 1} onClick={() => this.handleDeleteButtonClick(displayAlertDialog)}>{deleteButtonText}</Button>}
                                {importButton &&
                                <div className="display-inline-block export-div" >
                                    <Files
                                        ref={this.myFiles}
                                        className='files-dropzone'
                                        onChange={this.onFilesChange}
                                        onError={this.onFilesError}
                                        // accepts={(importFileTypes && importFileTypes.length > 0) ? importFileTypes : []}
                                        accepts={importFileTypes} // skip this field for allowing all file types instead of denying all (empty array results in denying all)
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        <Button type="button" className="import-button" onClick={() => this._importButtonAction()}>{importButtonText}</Button>
                                    </Files>
                                </div>
                                }

                                {exportButton && <Button type="button" className="primary-button" onClick={() => this._exportButtonAction()}>{exportButtonText}</Button>}
                                {remainButtons && remainButtons.map((remainButton, idx) => (
                                    <Button
                                        key={idx}
                                        className={remainButton.className}
                                        disabled={remainButton.selectedOnly ? selected.length < 1 : false}
                                        onClick={remainButton.onClick ? () => remainButton.onClick(selected, rowsPerPage) : null}
                                    >
                                        {remainButton.text}
                                    </Button>
                                ))}
                            </Grid>
                            <Grid item xs={12} md={hasLeftActions && hasRightActions ? 6 : 12} className="button-wrapper form-item flex-end-item-btn">
                                {sendWeChatButton && <Button type="button" className="send-button" onClick={() => this._sendWeChatButtonAction()}>{sendWeChatButtonText}</Button>}
                                {sendPushButton && <Button type="button" className="send-button" onClick={() => this._sendPushButtonAction()}>{sendPushButtonText}</Button>}
                                {sendOneToOneButton && <Button type="button" className="send-button" onClick={() => this._sendOneToOneButtonAction()}>{sendOneToOneButtonText}</Button>}
                            </Grid>
                        </Grid>

                    </div>
                    }

                    <Grid container alignItems="center">
                        <EnhancedTable
                            primaryKey={primaryKey}
                            displaySelectBox={displaySelectBox}
                            rows={rows}
                            data={data}
                            selected={selected}
                            order={order}
                            orderBy={orderBy}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            amountOfData={amountOfData}
                            isSelected={this.isSelected}
                            selectRow={this.handleClick}
                            handleSelectAllClick={this.handleSelectAllClick}
                            handleRequestSort={this.handleRequestSort}
                            handleChangePage={this.handleChangePage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            clickToFunction={this.clickToFunction}
                        />
                    </Grid>

                    {displayBottomFunction &&
                    <div className='button-wrapper action-bar'>
                        <Grid container xm={12} alignItems="center">
                            <Grid item xs={12} md={12} className="button-wrapper form-item">
                                {okButton && <Button type="button" className="primary-button" disabled={selected.length !== 1} onClick={() => this._okButtonAction()}>{okButtonText}</Button>}
                                {cancelButton && <Button type="button" className="back-button" onClick={() => this._cancelButtonAction()}>{cancelButtonText}</Button>}
                            </Grid>
                        </Grid>

                    </div>
                    }
                </div>
            </div>
        )
    }
}

BaseListPage.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    setPaginator: data => dispatch(setPaginator(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(BaseListPage))));
