import {api} from "./_ApiFactoryWithHeader";

export const apiClient = {
    getClients: (params) => {
        return api.get('clients', params, null)
    },
    getClientDetail: (id, params) => {
        return api.get(`clients/${id}`, params, null)
    },
    getClientDetailByTenantMemberId: (tenantMemberId, clientParams) => {
        return api.get(`tenant_members/${tenantMemberId}`, {
            $select: 'client'
        }).then(res => {
            if (res && res.status === 200 && res.data) {
                const clientId = res.data.client;
                return apiClient.getClientDetail(clientId, clientParams);
            } else {
                return res;
            }
        });
    },
    addClient: (body) => {
        return api.post(`clients`, body)
    },
    updateClient: (id, body) => {
        return api.put(`clients/${id}`, body)
    },
    updateClientWithPost: (id, body) => {
        return api.post(`clients/${id}`, body)
    },
    deleteClient: (id, params) => {
        return api.delete(`clients/${id}`, params);
    },
    getCreditHistories: (params) => {
        return api.get(`credit-histories`, params, null)
    },
    addCredit: (body) => {
        return api.post(`credits`, body)
    },
    exportCredit: (clientId, params) => {
        return api.getXlsxFile(`credits-export/${clientId}`, params, null)
    }

};
