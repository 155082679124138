import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

import PeopleIcon from '@material-ui/icons/People';
import TuneIcon from '@material-ui/icons/Tune';
import GavelIcon from '@material-ui/icons/Gavel';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import SendIcon from '@material-ui/icons/Send';

const adminCmsMenuItems = [
    {
        urls: [null, '', 'tenant-management'],
        to: '/tenant-management',
        icon: <PeopleIcon />,
        label: 'Sidebar:tenant-mgt',
    }
];

const tenantCmsMenuItems = [
    {
        urls: [null, '', 'auction-management', 'auction'],
        to: '/auction-management',
        icon: <GavelIcon />,
        label: 'Sidebar:auction-mgt',
    },
    {
        urls: ['client-management', 'client', 'add-credit', 'send-msg', 'send-push-msg', 'send-wechat'],
        to: '/client-management',
        icon: <PeopleIcon />,
        label: 'Sidebar:client-mgt',
    },
    {
        urls: ['push-msg-schedule'],
        to: '/push-msg-schedule',
        icon: <MailOutlineIcon />,
        label: 'Sidebar:push-msg-schedule',
    },
    {
        urls: ['report'],
        to: '/report',
        icon: <DescriptionIcon />,
        label: 'Sidebar:report',
    },
    {
        urls: ['configuration'],
        to: '/configuration',
        icon: <TuneIcon />,
        label: 'Sidebar:configuration',
    },
    // {
    //     urls: ['msg-history'],
    //     to: '/msg-history',
    //     icon: <SendIcon />,
    //     label: 'Sidebar:msg-history',
    // }
];

class Sidebar extends Component {

    render() {
        const { t, i18n, auth, currentURL } = this.props;
        let menuItems;
        if (auth.userInfo.role == 1 && !localStorage.getItem('asTenantId')) {
            menuItems = adminCmsMenuItems;
        } else {
            menuItems = tenantCmsMenuItems;
        }
        return (
            <div className="sidebar__container">
                <div className="sidebar__container_logo">
                    <div className="sidebar__container_desc">
                        <p className="sidebar__container_title color-white">eGoBid</p>
                        <p>Content Management System</p>
                        <p>v 1.0</p>
                    </div>
                </div>
                {auth.auth &&
                    <div className="sidebar-menu">
                        <List>
                            {menuItems.map((menuItem, i) => {
                                const { urls, to, icon, label } = menuItem;
                                return (
                                    <ListItem
                                        key={i}
                                        className={urls.includes(currentURL) ? 'active' : ''}
                                        component={Link}
                                        to={`/${i18n.language}${to}`}
                                    >
                                        <ListItemIcon className="menu-item-icon" >{icon}</ListItemIcon>
                                        <ListItemText primary={t(label)} />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));