import {api} from './_ApiFactoryWithHeader';

export const apiUsers = {
    getUserList: (params, cancelToken) => api.get('users', params, null, cancelToken),

    getUserDetail: (username, params) => api.get(`users/${username}`, params, null, null),

    createUser: (body) => {
        return api.post('users', body)
    },

    updateUser: (username, body) => {
        return api.put(`users/${username}`, body)
    },

    updateAccessRights: (body) => {
        return api.put(`access_rights_mass_update`, body)
    },

    uploadNewProfileImage(fileData) {
        return api.post('files', fileData)
    },

    deleteUser: (usernames) => {
        return api.delete(`users?username%5Bin%5D=${encodeURIComponent(usernames)}`, null, null, null)
    },

    changePassword: (username, body) => {
        return api.put(`change_password/${username}`, body)
    },
    changePasswordUser: (username, body) => {
        return api.put(`users/${username}/reset_password/`, body)
    },

    getClientDetails: (params) => {
        return api.get('user_profiles', params, null)
    },
    addClientDetail: (body) => {
        return api.post(`user_profiles`, body)
    },
    updateClientDetail: (id, body) => {
        return api.put(`user_profiles/${id}`, body)
    },
    getCreditHistories: (params) => {
        return api.get(`credit_histories`, params, null)
    },

    addCredit: (username, body) => {
        return api.post(`users/${username}/add_credit/`, body)
    },
    sendOneToOneMsg: (username, body) => {
        return api.post(`messages`, body)
    },

    getWechatMsgTemplates: (params) => {
        return api.get(`wechat_templates`, params, null)
    },
    sendWechatMsg: (wechatTemplateId, params) => {
        return api.get(`/wechat_templates/${wechatTemplateId}/send_message`, params, null)
    },
};
