// Essential for all components
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
// Api
import {logout} from "../../Redux/Action/authAction";

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";


const styles = {
    appBar: {
        width: `100%`,
        backgroundImage: 'linear-gradient(to right, black , red)',
        alignItems: 'center',
    },
    typography: {
        flexGrow: 1,
        align: "center"
    }
};

class PublicHeader extends Component {
    render() {
        const { classes, t, i18n } = this.props;
        return (
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <Typography color="inherit" className={classes.grow}>
                        <Link to={"/" + i18n.language + '/'} className="color-white font-default">{t("Common:General.Title")}</Link>
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    }
}

PublicHeader.propTypes = {
    siteType: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    router: state.router,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    logoutP: data => dispatch(logout(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(PublicHeader))));