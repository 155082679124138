// Essential for all components
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Styling
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

// Api
import { apiAuth } from '../../Api/ApiAuth';

// Redux
import { connect } from 'react-redux';
import { login, forgetPasswordClear } from '../../Redux/Action/authAction';

// Utils
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import GeneralMessage from '../../components/100Include/GeneralMessage';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MessageContent: ''
        }
    }

    _resetPasswordAsync = (values) => {
        this.setState({ MessageContent:''})
        if (typeof (values) !== 'undefined') {
            var promise = Promise.resolve();
            apiAuth.getClientCredentials().then((res) => {
                promise.then(() => {
                    this._resetPassword(values, res.data.access_token);
                });
            })
        }
    }

    _resetPassword = (values, token) => {
        console.log("reset password",values,"token",token);
        const { i18n } = this.props;
        const cb = (obj) => {
            console.log("cb",obj); 
            if(obj && obj.status ===204 && obj.ok){
                this.setState({ MessageContent:"Please wait"});
                setTimeout(() => {
                    // clear the reset password username in redux
                   
                    this.props.forgetPasswordClearP();
    
                    this.props.history.push('/' + i18n.language + '/reset-password-success');
                }, 3000);
            }else{
                this.setState({ MessageContent:"Error invalid temporary password"})
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const body = {
            old: {
                password: values.tempPassword
            },
            new: {
                password: values.password
            }
        };

        apiAuth.resetPassword(this.props.auth.forgetPasswordBy, body, token, cb, eCb);
    }

    formResetPassword = ({ values, errors, touched, handleChange }) => {
        const { t, i18n } = this.props;

        return (
            <Grid item xm={12} md={6} xl={4} className="grid">
                <Form id="resetPasswordForm" className="form-wrapper forgot-form">
                    <Grid item xs={12} className="grid title">
                        <h1>{t('Common:General.ResetPassword')}</h1>
                        <h3>{t("LoginRegister:resetPassword.description")}</h3>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className="grid">
                            <Field name="tempPassword" type="password" placeholder={t("LoginRegister:placeholder.tempPassword")} maxLength="100" style={{ 'width': '100%' }} />
                            {errors.tempPassword && touched.tempPassword ? <ErrorMessage message={errors.tempPassword} /> : null}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Field name="password" type="password" placeholder={t("LoginRegister:placeholder.newPassword")} maxLength="100" style={{ 'width': '100%' }} />
                            {errors.password && touched.password ? <ErrorMessage message={t("LoginRegister:placeholder.passwordRequirement")} /> : null}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Field name="confirmPassword" type="password" placeholder={t("LoginRegister:placeholder.confirmNewPassword")} maxLength="100" style={{ 'width': '100%' }} />
                            {errors.confirmPassword && touched.confirmPassword ? <ErrorMessage message={errors.confirmPassword} /> : null}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Button type="submit">{t("Common:General.Reset")}</Button>
                        </Grid>
                    
                        <Grid item xs={12} className="SuccessMessage">
                            {this.state.MessageContent !== ''?
                            <GeneralMessage
                                message={this.state.MessageContent}
                            />:null}
                            
                        </Grid>
                        <Grid item xs={12} className="grid align-center">
                            <Link to={"/" + i18n.language + '/login'}>{t("LoginRegister:forgetPassword.backTo")}</Link>
                        </Grid>
                    </Grid>
                </Form>
            </Grid>
        )
    }

    render() {
        // Redirect to home
        if (this.props.auth.auth) {
            return <Redirect push to={"/"} />
        }

        const Schema = Yup.object().shape({
            tempPassword: Yup.string()
                .typeError('Temporary Password must be a valid string')
                .required('Temporary Password is required'),
            password: Yup.string()
                .typeError('New Password must be a valid string')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Does not match Password requirements!")
                .required('New Password is required'),
            confirmPassword: Yup.string()
                .typeError('Confirm New Password must be a valid string')
                .oneOf([Yup.ref('password'), null], "Does not match with Password!")
                .required('Confirm New Password is required'),
        })

        return (
            <div className="forgot-page">
                <div className="main__container flex-center-item">
                    <Formik
                        initialValues={{
                            tempPassword: '',
                            password: '',
                            confirmPassword: '',
                        }}
                        validationSchema={Schema}
                        onSubmit={this._resetPasswordAsync}
                        component={this.formResetPassword}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    forgetPasswordClearP: data => dispatch(forgetPasswordClear(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword)));
