import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import withStyles from '@material-ui/styles/withStyles';
import moment from "moment";

const styles = theme => ({
    wrapper: {
        width: '100%',
    },
    calendar: {
        lineHeight: 'initial',
        '& .react-datepicker__header__dropdown': {
            marginTop: 4,
            marginBottom: 4,
        },
        '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow': {
            top: 4,
        },
    },
});

class CustomInput extends React.Component { // must be class components for giving ref
    render = () => (
        <input {...this.props} type="text" readOnly style={{ width: '100%' }} />
    )
}

export const CustomDatePicker = withStyles(styles)(({ value, onChange, classes, ...props }) => (
    <DatePicker
        selected={(value && new Date(value)) || null}
        showYearDropdown
        showMonthDropdown
        calendarClassName={classes.calendar}
        clas
        wrapperClassName={classes.wrapper}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        onChange={onChange}
        stykle
        {...props}
    />
));

export const DatePickerField = ({ classes, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <CustomDatePicker
            {...field}
            {...props}
            onChange={val => {
                setFieldValue(field.name, moment(new Date(val)).format('YYYY-MM-DD'));
            }}
        />
    );
};

export default DatePickerField;
