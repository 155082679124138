// Essential for all components
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';

class Breadcrumb extends Component {

    getLinkByItem = (item) => {
        return item.link ? (<Link to={item.link}>{item.title}</Link>) : (<span>{item.title}</span>);
    }

    render() {
        const {breadcrumbArr} = this.props;
        return (
            <span className="breadcrumb__container">
                {
                    breadcrumbArr && breadcrumbArr.map((item, index) => {
                        if(index === 0) {
                            return (<span key={index}>{this.getLinkByItem(item)}</span>)
                        } else {
                            return (<span key={index}><span className="breadcrumb__separate"> > </span><span>{this.getLinkByItem(item)}</span></span>);
                        }
                    })
                }
            </span>
        );
    }
}

const mapStateToProps = (state) => ({
    route: state.router,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});

const mapDispatchToProps = dispatch => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Breadcrumb));
