// export const WEBSITE_URL = 'http://localhost:8080';
// export const WEBSITE_URL = '';
export const WEBSITE_URL = 'https://www.egobidder.com';
export const PARTIAL_API_URL = WEBSITE_URL + '/egobid-server-';
export const API_CENTRAL_URL_BASE = PARTIAL_API_URL + 'core';
export const API_ATT_URL_BASE = PARTIAL_API_URL + 'att';
export const API_SAMSUNG_OEM_URL_BASE = PARTIAL_API_URL + 'samsung';
export const API_BELL_URL_BASE = PARTIAL_API_URL + 'bell';
export const API_T_MOBILE_URL_BASE = PARTIAL_API_URL + 't-mobile';
export const API_GAMESTOP_URL_BASE = 'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-gamestop';
export const API_COSTCO_URL_BASE = 'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-costco';
export const API_SPRINT_URL_BASE =  'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-sprint';
export const API_ASSURANT_URL_BASE =  'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-assurant';
export const API_ONTRONICS_URL_BASE =  'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-ontronics';
export const API_SHINE_URL_BASE =  'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-shine';
export const API_ECOATM_URL_BASE =  'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-ecoatm';
export const API_HYLA_URL_BASE =  'http://ec2-18-167-172-113.ap-east-1.compute.amazonaws.com/egobid-server-hyla';
export const API_TRILLION_ATT_URL_BASE = PARTIAL_API_URL + 'trillion-att';
export const API_XINGQIU_ATT_URL_BASE = PARTIAL_API_URL + 'xingqiu-att';
export const API_XINGQIU_HYLA_URL_BASE = PARTIAL_API_URL + 'xingqiu-hyla';
export const API_XINGQIU_SPRINT_URL_BASE = PARTIAL_API_URL + 'xingqiu-sprint';
export const API_CENTRAL_URL = API_CENTRAL_URL_BASE + '/';
export const API_ATT_URL = API_ATT_URL_BASE + '/';
export const API_SAMSUNG_OEM_URL = API_SAMSUNG_OEM_URL_BASE + '/';
export const API_BELL_URL = API_BELL_URL_BASE + '/';
export const API_T_MOBILE_URL = API_T_MOBILE_URL_BASE + '/';
export const API_GAMESTOP_URL = API_GAMESTOP_URL_BASE + '/';
export const API_COSTCO_URL = API_COSTCO_URL_BASE + '/';
export const API_SPRINT_URL = API_SPRINT_URL_BASE + '/';
export const API_ASSURANT_URL = API_ASSURANT_URL_BASE + '/';
export const API_ONTRONICS_URL = API_ONTRONICS_URL_BASE + '/';
export const API_SHINE_URL = API_SHINE_URL_BASE + '/';
export const API_ECOATM_URL = API_ECOATM_URL_BASE + '/';
export const API_HYLA_URL = API_HYLA_URL_BASE + '/';
export const API_TRILLION_ATT_URL = API_TRILLION_ATT_URL_BASE + '/';
export const API_XINGQIU_ATT_URL = API_XINGQIU_ATT_URL_BASE + '/';
export const API_XINGQIU_HYLA_URL = API_XINGQIU_HYLA_URL_BASE + '/';
export const API_XINGQIU_SPRINT_URL = API_XINGQIU_SPRINT_URL_BASE + '/';
export const PLATFORM_ATT_ID = 'c7370973-2197-4c90-be9a-ecc109c29749';
export const PLATFORM_SAMSUNG_OEM_ID = '18823677-d7cf-45de-bbba-aa3c9c57d3ba';
export const PLATFORM_BELL_ID = 'f71750f0-79fe-4a0d-b8e5-44aef342024c';
export const PLATFORM_T_MOBILE_ID = '8ac50f31-11a0-48ee-9401-33851b30db7c';
export const PLATFORM_GAMESTOP_ID = '490c6e90-1189-4e31-a6c5-8588de88dbcf';
export const PLATFORM_COSTCO_ID = '74cb5563-cc9f-4691-965f-9e89983aea2d';
export const PLATFORM_SPRINT_ID = 'c53a2c4b-08ef-4726-89f8-c2d9e2ba0b1d';
export const PLATFORM_ASSURANT_ID = 'a8b2a114-7808-4032-9f05-fdf9ee4482b3';
export const PLATFORM_ONTRONICS_ID = '836b37aa-40d5-4715-b1df-7d21ff9da49e';
export const PLATFORM_SHINE_ID = '0abf7852-adf9-4631-ad4a-74d0351e93db';
export const PLATFORM_ECOATM_ID = '1b90ebb2-31ec-4535-a331-0b6475fb5129';
export const PLATFORM_HYLA_ID = '4a961ada-8d30-413d-808b-f524b69ffb46';
export const PLATFORM_TRILLION_ATT_ID = 'b2f1bdc8-41af-4bf6-80be-03e6b191dea2';
export const PLATFORM_XINGQIU_ATT_ID = '06b1f676-07d2-4112-8e81-3ec92b95e328';
export const PLATFORM_XINGQIU_HYLA_ID = 'a028dc0c-fc05-4015-8fab-4028fabc4415';
export const PLATFORM_XINGQIU_SPRINT_ID = 'c8457868-028c-4ff6-bc9a-9b466c224ca1';
export const GA_TRACKING_ID = 'UA-xxxxxxxx';

export const SCREEN_SIZE_SM = 768;
export const SCREEN_SIZE_MD = 992;
export const SCREEN_SIZE_LG = 1200;
