// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import {Button, MenuItem} from '@material-ui/core';
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {map} from 'lodash-es';
import ErrorMessage from "../../components/100Include/ErrorMessage";
import FormControl from "@material-ui/core/FormControl";
import DatePickerField from "../../components/103FormikCustom/DatePickerField";
import {DayOfWeek} from "../../constants/common";
import {getDayOfWeekSelectedDisplay} from "../../utils/Utility";
import {addMessage} from "../../Redux/Action/messageAction";
import moment from "moment";
import {apiMessage} from "../../Api/_ApiMessage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SendPushMsg extends Component {

    state = {
        repeatedDays: [],
        sendPushMsg: null,
        checkedAll: false
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const {t, i18n} = this.props;
        this.props.setBreadcrumbP(
            {
                breadcrumbData: [
                    {
                        title: t('ClientManagement:breadcrumb'),
                        link: '/' + i18n.language + '/client-management'
                    },
                    {
                        title: t('ClientManagement:sendPushMsg.title'),
                        link: null
                    }
                ]
            }
        );
        const now = moment().format('YYYY-MM-DD');
        this.setState({
            sendPushMsg: {
                send_date: 'immediate',
                repeated_time: '09:30',
                selected_date: now,
                selected_time: '09:30',
                title: '',
                content: ''
            },
            repeatedDays: ['1']
        })
    }

    _submitForm = (value) => {
        const {i18n} = this.props;
        let returnUrl = 'push-msg-schedule';
        let body = {
            tenant: localStorage.getItem('asTenantId'),
            "title": value.title,
            "content": value.content,
            "is_send_all": true,
        };
        if (value.send_date === "immediate") {
            body['send_type'] = 'immediate';
            returnUrl = 'client-management';
        } else if (value.send_date === "schedule") {
            let selectedDateTime = moment(`${value.selected_date} ${value.selected_time}`, 'YYYY-MM-DD HH:mm')
            body['send_type'] = 'schedule';
            body['fix_schedule_send_date'] = selectedDateTime.valueOf();
        } else if (value.send_date === "repeat") {
            let repeatedDays = this.state.repeatedDays.length > 0 ? this.state.repeatedDays.filter(item => item !== "" && item !== "All") : [];
            body['send_type'] = 'repeat';
            body['repeat_send_week_day'] = repeatedDays.length > 0 ? repeatedDays.join(',') : '';
            body['repeat_send_hour'] = value.repeated_time;
        }

        apiMessage.pushNotification(body).then(obj => {
            if (obj.status === 201) {
                const msgDsp = {
                    messageSnackbar: 'Send Push Message is sent successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + `/${returnUrl}?previous=true`)
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Send Push Message To All failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _cancelButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client-management?previous=true');
    }

    handleChange = event => {
        const target = event.target;
        const value = target.value;
        const newState = {};
        switch (target.name) {
            case 'repeatedDays':
            case 'repeated_time':
                newState.send_date = 'repeat';
                break;
            case 'selected_date':
            case 'selected_time':
                newState.send_date = 'schedule';
                break;
        }
        if(this.state.repeatedDays.includes('All')) {
            if(!value.includes('All')) {
                newState.repeatedDays = [];
                newState.checkedAll = false;
            } else {
                newState.repeatedDays = value.filter(item => item !== "" && item !== "All");
                newState.checkedAll = false;
            }
        } else {
            if(value.includes('All')) {
                const selected = map(DayOfWeek, 'value');
                selected.push('All');
                newState.repeatedDays = selected;
                newState.checkedAll = true;
            } else {
                newState.repeatedDays = value.filter(item => item !== "" && item !== "All");
                newState.checkedAll = false;
            }
        }
        this.setState(newState);
    };

    // FORM CONFIG
    formConfiguration = ({values, errors, touched, handleChange}) => {
        const {t} = this.props;
        return values && (
            <Form id="userForm" className="full-width" autoComplete="off">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:sendPushMsg.sendDate")}
                        </Grid>
                        <Grid item xs={10} className="form-row">
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start"
                                      className="mt10">
                                    <Grid item xs={"auto"}>
                                        <input
                                            type="radio"
                                            name="send_date"
                                            value="immediate"
                                            checked={values.send_date === "immediate"}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        {t("ClientManagement:sendPushMsg.immediate")}
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start"
                                      className="mt10">
                                    <Grid item xs={"auto"}>
                                        <input
                                            type="radio"
                                            name="send_date"
                                            value="repeat"
                                            checked={values.send_date === "repeat"}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        {t("ClientManagement:sendPushMsg.repeated")}
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <FormControl className='full-width'>
                                            <Select
                                                classes={{
                                                    root: 'custom-select select-multiple-checkbox'
                                                }}
                                                name="repeatedDays"
                                                multiple
                                                disableUnderline={true}
                                                value={this.state.repeatedDays}
                                                onChange={this.handleChange}
                                                input={<Input className="select-multiple-checkbox-input"/>}
                                                renderValue={selected => (`Every ${getDayOfWeekSelectedDisplay(selected)}`)}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem key="All" value="All">
                                                    <Checkbox checked={this.state.checkedAll}/>
                                                    <ListItemText primary={'All'}/>
                                                </MenuItem>
                                                {map(DayOfWeek, item => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        <Checkbox
                                                            checked={item && this.state.repeatedDays.indexOf(item.value) > -1}/>
                                                        <ListItemText primary={item.label}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <input type="time" name="repeated_time" value={values.repeated_time}
                                               onChange={handleChange}/>
                                    </Grid>
                                    {errors.repeatedDays && touched.repeatedDays && <Grid item xs={"auto"} className="ml20">
                                        <ErrorMessage message={errors.repeatedDays} />
                                    </Grid>}
                                    {errors.repeated_time && touched.repeated_time && <Grid item xs={"auto"} className="ml20">
                                        <ErrorMessage message={errors.repeated_time} />
                                    </Grid>}
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start"
                                      className="mt10">
                                    <Grid item xs={"auto"}>
                                        <input
                                            type="radio"
                                            name="send_date"
                                            value="schedule"
                                            checked={values.send_date === "schedule"}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <DatePickerField name="selected_date" dateFormat="yyyy-MM-dd"/>
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <input type="time" name="selected_time" value={values.selected_time}
                                               onChange={handleChange}/>
                                    </Grid>
                                    {errors.selected_date && touched.selected_date && <Grid item xs={"auto"} className="ml20">
                                        <ErrorMessage message={errors.selected_date} />
                                    </Grid>}
                                    {errors.selected_time && touched.selected_time && <Grid item xs={"auto"} className="ml20">
                                        <ErrorMessage message={errors.selected_time} />
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="mt20">
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:sendPushMsg.msgTitle")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="title" type="text" placeholder="" maxLength="40"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={9}>
                                        {errors.title && touched.title ? <ErrorMessage
                                            message={errors.title}/> : t("Common:Form.validator.required")}
                                    </Grid>
                                    <Grid item xs={3} className="align-right">
                                        {values.title ? values.title.length : 0} / 40 characters
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="mt20">
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:sendPushMsg.msgContent")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="content" component="textarea" rows="5" maxLength="178"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="flex-start">
                                    <Grid item xs={11} className="form-item">
                                        {errors.content && touched.content ? <ErrorMessage
                                            message={errors.content}/> : t("Common:Form.validator.required")}
                                    </Grid>
                                    <Grid item xs={1} className="form-item">
                                        {`${values.content ? values.content.length : 0} / 178 character`}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Send</Button>
                        <Button type="button" className="second-button" onClick={() => {
                            this._cancelButtonAction()
                        }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const {sendPushMsg} = this.state;
        const inputSendPushMsg = {...sendPushMsg};
        const Schema = Yup.object().shape({
            title: Yup.string().required("Title is required"),
            content: Yup.string().required("Content is required"),
            repeatedDays: Yup.string().when('send_date', {
                is: 'repeat',
                then: Yup.string().required('At least 1 weekday is required'),
                otherwise: Yup.string()
            }),
            repeated_time: Yup.string().when('send_date', {
                is: 'repeat',
                then: Yup.string().required('Time is required'),
                otherwise: Yup.string()
            }),
            selected_date: Yup.string().when('send_date', {
                is: 'schedule',
                then: Yup.string().required('Date is required'),
                otherwise: Yup.string()
            }),
            selected_time: Yup.string().when('send_date', {
                is: 'schedule',
                then: Yup.string().required('Time is required'),
                otherwise: Yup.string()
            }),
        });

        return (
            sendPushMsg && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputSendPushMsg}
                            validationSchema={Schema}
                            onSubmit={this._submitForm}
                            component={this.formConfiguration}
                        />
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SendPushMsg)));
